/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { container } from '@stores/config';
import { Localisation, User, BillingAddress, Order, Auth, FundingContract } from '@stores/domains';
import { TYPES } from '@stores/types';

export type Store = {
  user: User;
  billingAddress: BillingAddress;
  fundingContract: FundingContract;
  localisation: Localisation;
  order: Order;
  auth: Auth;
};

export const createStore = (): Store => {
  const localisation = container.get<Localisation>(TYPES.Localisation);
  const user = container.get<User>(TYPES.User);
  const billingAddress = container.get<BillingAddress>(TYPES.BillingAddress);
  const order = container.get<Order>(TYPES.Order);
  const auth = container.get<Auth>(TYPES.Auth);
  const fundingContract = container.get<FundingContract>(TYPES.FundingContract);

  return {
    localisation,
    billingAddress,
    user,
    order,
    auth,
    fundingContract,
  };
};
