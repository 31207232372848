/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { FundingContractData } from '@components/CheckoutForm/types';

@injectable()
export class FundingContract {
  fundingContracts: FundingContractData[];
  changePaymentMethod = false;

  constructor() {
    makeObservable(this, {
      fundingContracts: observable,
      changePaymentMethod: observable,
      addFundingContracts: action,
      setMostRecentFundingContract: observable,
      resetMostRecentFundingMethod: observable,
    });
  }

  setChangePaymentMethod(changePaymentMethod: boolean) {
    this.changePaymentMethod = changePaymentMethod;
    // Reset the most recent funding method to prevent the saved card checkout appearing
    this.resetMostRecentFundingMethod();
  }

  addFundingContracts(fundingContracts: FundingContractData[]): void {
    const sortedFundingContracts = fundingContracts.sort(
      (a, b) => new Date(b.lastAuthorisationDateTime).getTime() - new Date(a.lastAuthorisationDateTime).getTime()
    );

    if (sortedFundingContracts.length) {
      this.fundingContracts = sortedFundingContracts;
      this.setMostRecentFundingContract(sortedFundingContracts[0]);
    }
  }

  getMostRecentFundingContract(): FundingContractData | null {
    const fundingContract = sessionStorage.getItem('mostRecentFundingContract');

    return fundingContract ? (JSON.parse(fundingContract) as FundingContractData) : null;
  }

  setMostRecentFundingContract(fundingContract: FundingContractData) {
    if (fundingContract) sessionStorage.setItem('mostRecentFundingContract', JSON.stringify(fundingContract));
  }

  resetMostRecentFundingMethod(): void {
    sessionStorage.removeItem('mostRecentFundingContract');
  }
}
